<svelte:options tag="u-pick-director" />

<script lang="ts">
  // import { Region } from './global.js';
  import { onMount, onDestroy } from "svelte";
  import { DOMAIN, API_PATH } from "./constants";
  import { t, locale, locales } from "./i18n";

  import {
    regionSelection,
    selectedFields,
    storedZones,
    selectedZone,
    storedForages,
    storedCharacteristics,
    formFields,
    installationTime,
    resetStorage,
    flashMessages,
    flushFlashMessages
  } from "./store";
  import Modal from "./Modal.svelte";
  import { nfCapitalize } from "./helpers.js";
  import type { IFlashMessage } from "./global";
  import { api, ApiMethodEnum, ApiMethodResource, ApiPageEnum } from './api';

  // import "../../u-pick-components/out-tsc/src/components/up-button/index.js";
  // import "../../u-pick-components/out-tsc/src/components/zone-selector/index.js";
  //   export let name: string;
  // regionSelection.update(() => ({ region: 'alberta', zone: 'black' }));
  // let showModalProvince = false;
  // let showModalZone = false;
  let showNavMenu = false;
  const domain = DOMAIN;
  const apiPath = API_PATH;
  // let ref;
  // onMount(() => {ref.focus()})

  const resetRegionSelection = () => {
    selectedZone.update(() => ({}));
    regionSelection.update(() => ({ zone: "", region: "" }));
    selectedFields.update(() => []);
    formFields.update(() => []);
    storedCharacteristics.update(() => []);
  };

  onMount(() => {
    installationState();
    selectedZone.subscribe(() => {
      processFields();
    });
    storedZones.subscribe(() => {
      // console.log("Region Selection: ", $regionSelection);
      // console.log("Stored Zones: ", $storedZones);
      const zone = $storedZones.filter(
        (z) => z.slug === $regionSelection.zone
      )[0];
      // console.log("Filtered Zones: ", zone);
      if (zone)
        selectedZone.update(() => $storedZones[$storedZones.indexOf(zone)]);
      // console.log("selected zone: ", $selectedZone);
    });
    window.onhashchange = hashChange;
    locationChange();
    fetchForages();
    fetchZones();
  });
  onDestroy(() => {
    window.onhashchange = null;
  });
  // export const toggleShowProv = () => {
  //   showModalProvince = !showModalProvince;
  // };

  const installationState = () => {
    const clearLimit = 60 * 60 * 1 * 1000;
    const difference = Date.now() - clearLimit;
    if($installationTime === 0) {
      $installationTime = Date.now();
    } else if ($installationTime < difference) {
      resetStorage();
    } 
  }

  const fetchForages = async () => {
    if ($storedForages.length > 0) return;
    const forages = [];
    // const request = await fetch(DOMAIN + API_PATH + "forages?per_page=100");
    // const data = await request.json();
    const request = await api(ApiMethodEnum.GET, ApiMethodResource.FORAGES);

    const data = request.body;
  
    data.map((forage) =>
      forages.push({
        id: forage.id,
        title: forage.title.rendered,
        slug: forage.slug,
        characteristic: forage.characteristic,
        zones: forage.zones,
        forageType: forage.forage_type,
        ...forage.meta,
        seeds_kg: parseInt(forage.meta.seeds_kg) || undefined,
        seeds_lb: parseInt(forage.meta.seeds_lb) || undefined,
        pls_sqft: parseInt(forage.meta.pls_sqft) || undefined,
        isBare: true,
        coatedPercentage: 0.2,
        percentInStand: 1,
        cost: 0,
        plsSeedlot: parseInt(forage.meta.pls_percent) || 79,
        usePls: true,
        plsPurityPercentage: parseInt(forage.meta.pls_purity)|| 98,
        plsGerminationPercentage: parseInt(forage.meta.pls_germination) || 75,
        calculation: undefined,
        runCalculation: undefined,
      })
    );
    // console.log(forages);
    storedForages.set(forages);
  };
  const fetchZones = async () => {
    if ($storedZones.length > 0) return;
    const zones = [];
    const request = await fetch(DOMAIN + API_PATH + "zones?per_page=100");
    const data = await request.json();
    data.map((zone) =>
      zones.push({
        id: zone.id,
        // title: zone.title.rendered,
        title: (zone.meta.alt_title == "") ? zone.title.rendered : zone.meta.alt_title,
        slug: zone.slug,
        ...zone.meta,
        pls_sqft: parseInt(zone.meta.pls_sqft) || undefined,
        calculator: (zone.meta.calculator == "on") ? true : false,
      })
    );
    // console.log(zones);
    storedZones.set(zones);
  };
  const locationChange = () => {
    const path = window.location.pathname.split("/")[1] || undefined;
    if (
      pathCheck(path) &&
      ($regionSelection.region === "" || $regionSelection.zone === "")
    ) {
      window.location.href = "/select-soil-zone";
    }
  };

  const processForages = async () => {
    const flashForages: IFlashMessage = { route: "forage"};
    flushFlashMessages([flashForages]);
    const forageElms = document.getElementsByClassName(
      "forageListing-listing-list-item-link"
    );
    const modalElms = document.getElementsByTagName("up-forage-modal");
    console.log(modalElms);
    // console.log(forageElms);
    // const request = await fetch(domain + apiPath + 'forages?per_page=100');
    // const forages: any[]= await request.json()
    // console.log(forages);
    // console.log($selectedFields);
    // console.log("processForages formFields", $formFields);
    // console.log("forageElm", forageElms);
    const activeFields = [];
    $formFields.forEach((field) => {
      let currentField = field;
      let activeItem = field.items.find((c) => c.id === currentField.checkedId);
      if (activeItem !== undefined) {
        activeFields.push(activeItem.postId);
      }
      // console.log(forage.title, inactive)
    });
    console.log("Active Fields", activeFields);
    selectedFields.update(() => activeFields);

    let sortedForages = $storedForages;
    $selectedFields.forEach((field) => {
      sortedForages = sortedForages.filter(
        (c) => c.characteristic.includes(field) === true
      );
    });
    // console.log("Sorted Forages", sortedForages);
    if ($selectedFields.length > 0) {
      let activeCount = 0;
      for (let index = 0; index < forageElms.length; index++) {
        let element = forageElms[index] as HTMLElement;
        let test = sortedForages.filter(
          (forage) => forage.id === parseInt(element.dataset.postid)
        ).length;
        // console.log(test);
        if (test === 0 || sortedForages.length === 0) {
          console.log("Seeting forage inactive", element);
          console.log("modal elms index", modalElms[index]);
          element.classList.add("inactive");
          modalElms[index].setAttribute("active", false.toString());
        } else {
          activeCount = activeCount + 1;
        }
      }
      if (activeCount === 0) {
        flashMessages.update((messages) => [flashForages, ...messages]);
      } 
    }
  };

  const processFields = async () => {
    if ($selectedZone.id === undefined) return;
    const requestProv = await fetch(
      domain + apiPath + `province?slug=${$regionSelection.region}`
    );
    const provinces: any[] = await requestProv.json();
    const province = provinces[0];
    // console.log(provinces);

    const requestZones = await fetch(
      domain + apiPath + `zones?slug=${$regionSelection.zone}`
    );
    const zones = await requestZones.json();
    const zone = zones[0];
    // console.log(zones);

    const characteristics: any[] = zone.characteristic
      ? zone.characteristic
      : [];
    // console.log(characteristics);
    storedCharacteristics.update(() => [...characteristics]);
  };

  const pathCheck = (path) => {
    switch (path) {
      case "field":
        // processFields();
        return true;
      case "seeding-rate-calculator":
        return false;
      case "forage":
        processForages();
        return true;
      default:
        // processFields();
        return false;
    }
  };
  const hashChange = () => {
    if (window.location.hash.match("select-soil-zone")) {
      const hashArray = window.location.hash.split("/").filter((c) => c !== "").filter((c) => c !== "#");
      let region, zone = undefined;
      if (hashArray.length > 2) {
        region = hashArray[hashArray.length - 2];
        zone = hashArray[hashArray.length - 1];
      } else {
        region = hashArray[hashArray.length - 1];
      }
      if (zone !== undefined) {
        regionSelection.update((r) => ({
          ...r,
          region: region,
          zone: zone,
        }));
        window.location.href = `field`;
      } else {
        regionSelection.update((r) => ({
          ...r,
          region: region,
        }));
        window.location.href = `/${$regionSelection.region}`;
      }
      // toggleShowProv();
      // showModalZone = true;
      // console.log($regionSelection);
    }
  };

  const toggleNav = () => {
    showNavMenu = !showNavMenu;
  };

  const regionTitle = () => {
    const currentRetion = $storedZones.filter( z => z.slug === $regionSelection.zone);
    return currentRetion.length > 0 ? currentRetion[0].title : undefined;
  }
</script>

<main class="head-bottom">
  <a href="/" class="head-bottom-logo">
    <img
      src="{$locale === 'en' ? '/assets/images/logo.png' : '/assets/images/logo-fr.png'}"
      alt={$t("Forage_U_Pick")}
      title={$t("Return_to_Home")}
    />
  </a>

  <div class="head-bottom-nav">
    <ul>
      <li><a href="/field">{$t("Forages_Suited_to_My_Field")}</a></li>
      <li><a href="/seeding-rate-calculator">{$t("Seeding_Rate_Calculator")}</a></li>
      <li><a href="/weed">{$t("Forage_Weed_Management")}</a></li>
    </ul>
  </div>

  {#if showNavMenu }
  <div class="mobile-nav-container">
    <ul>
      <li><a href="/field">{$t("Forages_Suited_to_My_Field")}</a></li>
      <li><a href="/seeding-rate-calculator">{$t("Seeding_Rate_Calculator")}</a></li>
      <li><a href="/weed">{$t("Forage_Weed_Management")}</a></li>
    </ul>
    <br>
    <hr>
    <br>
    <ul class="nav main-nav">
      <li class="">
        <a href="/glossary" target="_self" class=""
          >{$t("Glossary")}</a
        >
      </li>
      <li class="nav-selected nav-path-selected">
        <a
          href="/information"
          target="_self"
          class="nav-selected nav-path-selected">{$t("Information")}</a
        >
      </li>
      <li class="">
        <a
          href="/acknowledgements"
          target="_self"
          class="">{$t("Acknowledgements")}</a
        >
      </li>
      <li class="">
        <a href="{$t("mobile_language_link")}" target="_self" class=""
          >{$t("mobile_language_text")}</a
        >
      </li>
      <li class="help">
        <a href="/help" target="_self" class=""
          >{$t("Help")}</a
        >
      </li>
    </ul>
  </div>
  { /if }

  <div class="head-bottom-nav-expand">
    <a id="navPrimaryExpand">
      <div class="navWord">{$t("Main_Menu")}</div>
      <div class="navCircle" on:click={() => toggleNav()}>
        <div class="navHamburger bar1" />
        <div class="navHamburger bar2" />
        <div class="navHamburger bar3" />
      </div>
    </a>
  </div>

  <div class="head-bottom-soilZone">
    <div class="head-bottom-soilZone-title">
      <img src="/assets/images/icon-soil-zone.png" alt={$t("My_Soil_Zone")} />
      {$t("My_Zone")}
    </div>

    <up-button
      primary
      border
      title={regionTitle() || $t("Select_Zone")}
      icon="/assets/images/icon-switch.png"
      on:click={() => {
        resetRegionSelection();
        window.location.href = "/select-soil-zone";
      }}
    />
    <!-- <a
      href="/select-soil-zone"
      class="head-bottom-soilZone-select"
      title="Switch Soil Zone"
    >
      Select Zone <img src="/icon-switch.png" alt="Switch Soil Zone" />
    </a> -->
  </div>
</main>

<style>
  main {
    /* text-align: center;
    padding: 1em;
    max-width: 240px; */
    margin: 0 auto;
  }
  main * {
    box-sizing: border-box;
    -box-sizing: border-box;
    -moz-box-sizing: border-box;
    -text-size-adjust: none;
  }
  a {
    color: #a8bb18;
  }
  up-button {
    --up-button-icon-max-height: 25px;
    --up-button-icon-max-width: 25px;
    --up-button-background-primary: #fff;
    --up-button-border-color-primary: rgb(230, 231, 231);
    --up-button-background-primary-hover: #fff;
    --up-button-color-primary: #566909;
    --up-button-color-hover: #dab900;
    --up-button-font-size: 22px;
    --up-button-line-height: 22px;
    --up-button-padding: 15px;
    --up-button-min-width: 193px;
    --up-button-max-width: 288px;
    --up-button-text-align: left;
  }
  nf-modal {
    position: fixed;
    top: 55%;
    left: 0;
    width: 100%;
    z-index: 9999;
  }
  .head-bottom-logo img {
    width: 100%;
    max-width: 410px;
    height: auto;
  }
  .head-bottom-nav {
    float: left;
    width: 55%;
    padding-top: 90px;
    padding-left: 50px;
  }
  .head-bottom-nav ul {
    padding: 0;
    margin: 0;
    float: left;
  }
  .head-bottom-nav ul > li {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
    float: left;
  }
  .head-bottom-nav ul > li > a {
    text-decoration: none;
    position: relative;
    display: block;
    padding: 0 15px;
    font-family: "museo", arial, sans-serif;
    font-size: 22px;
    line-height: 22px;
    color: #566909;
  }
  .head-bottom-nav ul li a:hover::after {
  transform: scaleX(1);
}
  .head-bottom-nav ul > li > a:hover {
    color: #a8bb18;
  }

.head-bottom-nav ul li a:after {
  display: block;
    margin-top: 10px;
    content: '';
    border-bottom: solid 2px #a8bb18;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}
  .head-bottom-nav-expand {
    display: none;
  }
  .head-bottom-soilZone {
    float: right;
    width: auto;
    padding-top: 36px;
  }
  .head-bottom-soilZone-title {
    padding-bottom: 15px;
    font-family: "museo", arial, sans-serif;
    font-size: 16px;
    line-height: 16px;
    margin-left: 30px;
    color: #566909;
  }
  .head-bottom-soilZone-title img {
    width: 46px;
    height: auto;
    margin: -11px 10px 0 0;
  }
  .head-bottom-logo {
    display: block;
    float: left;
    width: 25%;
    padding-top: 60px;
  }

  .mobile-nav-container {
    float: none;
    display: block;
    width: 60%;
    padding-top: 90px;
    padding-left: 50px;
    color: #fff;
    z-index: 99999999;
  }
  .mobile-nav-container ul {
    list-style: none;
    float: none;
    padding: 0;
    margin: 0;
  }
  .mobile-nav-container ul li {
    padding: 15px;
  }
  .mobile-nav-container ul li a {
    padding: 15px;
    font-size: 25px;
    line-height: 30px;
    color: #fff;
    font-family: 'museo', arial, sans-serif;
    text-decoration: none;
  }

  @media (max-width: 1830px) {
    .head-bottom-logo {
      padding-top: 65px;
    }
    .head-bottom-nav {
      padding-left: 35px;
    }
    .head-bottom-nav ul > li > a {
      padding: 0 12px;
      font-size: 1.0vw;
      line-height: 1.2vw;
    }
  }
  @media (max-width: 1486px) {
    .head-bottom-logo {
      float: none;
      width: 400px;
      padding-top: 50px;
    }
    .head-bottom-nav {
      float: none;
      width: 75%;
      padding-top: 5px;
      padding-left: 54px;
    }
    .head-bottom-nav ul > li > a {
      font-size: 1.2vw;
      line-height: 1.5vw;
    }
    .head-bottom-soilZone {
      position: relative;
      /* top: 65px; */
      bottom: 5rem;
      right: 0;
      /* float: none; */
      /* width: 210px; */
      padding-top: 0;
    }
  }
  @media (min-width: 1023px) {
    .mobile-nav-container {
      display: none;
    }
  }
  @media (max-width: 1023px) {
    .head-bottom {
      margin-bottom: 0;
      text-align: center;
    }
    .head-bottom-logo {
      float: none;
      width: 100%;
      padding-top: 40px;
      padding-bottom: 30px;
    }
    .head-bottom-logo img {
      width: 100%;
      max-width: 500px;
    }
    .head-bottom-nav {
      display: none;
      position: absolute;
      top: 320px;
      width: 100%;
      padding: 40px;
      background: #a8bb18;
    }
    .head-bottom-nav ul {
      float: none;
    }
    .head-bottom-nav ul > li {
      float: none;
    }
    .head-bottom-nav ul > li > a {
      padding: 15px;
      font-size: 25px;
      line-height: 30px;
      color: #fff;
    }
    .head-bottom-nav-expand {
      position: relative;
      display: inline-block;
      width: 160px;
      padding: 25px 40px 20px 30px;
      top: -60px;
      font-family: "museo", arial, sans-serif;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      border-right: 2px solid #e6e7e7;
    }
    .head-bottom-nav-expand {
      position: relative;
      display: inline-block;
      width: 160px;
      padding: 25px 40px 20px 30px;
      top: -60px;
      font-family: "museo", arial, sans-serif;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      border-right: 2px solid #e6e7e7;
    }
    .head-bottom-nav-expand .navWord {
      margin-bottom: 20px;
      font-family: "museo", arial, sans-serif;
      font-size: 16px;
      line-height: 16px;
      color: #566909;
    }
    .head-bottom-nav-expand .navCircle {
      position: relative;
      margin: 0 auto;
      width: 60px;
      height: 60px;
      border: 3px solid #dab900;
      border-radius: 50%;
      cursor: pointer;
    }
    .head-bottom-nav-expand .navHamburger {
      position: absolute;
      left: 0;
      right: 0;
      width: 26px;
      height: 3px;
      margin: auto;
      background: #566909;
      transition: all 0.25s ease-out;
    }
    .head-bottom-nav-expand .navHamburger.bar1 {
      top: 18px;
    }
    .head-bottom-nav-expand .navHamburger.bar2 {
      top: 26px;
    }
    .head-bottom-nav-expand .navHamburger.bar3 {
      top: 34px;
    }
    .head-bottom-soilZone {
      position: relative;
      display: inline-block;
      width: 250px;
      /* padding-top: 25px; */
      /* padding-left: 40px; */
      /* top: auto; */
      /* right: auto; */
      float: none;
      left: 3rem;
      top: 0rem;
    }
    .mobile-nav-container {
    position: absolute;
    top: 320px;
    width: 100%;
    padding: 40px;
    background: #a8bb18;
    left: 0px;
    }
  }
  @media (max-width: 767px) {
    .head-bottom-logo {
      padding-bottom: 10px;
    }
    .head-bottom-nav {
      top: 240px;
      z-index: 999999999;
    }
    .head-bottom-nav ul:nth-child(1) {
      margin-bottom: 20px;
    }
    .head-bottom-nav-expand {
      width: 100px;
      padding: 25px 20px 20px 0;
      top: -50px;
      border-right: none;
    }
    .head-bottom-nav-expand .navWord {
      margin-bottom: 10px;
      font-size: 15px;
      line-height: 15px;
    }
    .head-bottom-soilZone {
      position: relative;
      display: inline-block;
      width: 195px;
      /* padding-top: 25px; */
      /* padding-left: 0; */
      top: auto;
      bottom: auto;
      left: 0;
      /* right: auto; */
    }

    .head-bottom-soilZone-title {
      display: block;
      font-size: 15px;
      line-height: 15px;
    }
    .head-bottom-soilZone-title img {
      display: none;
    }
    up-button {
      --up-button-font-size: 16px;
    }
  }
</style>
