<svelte:options tag="up-seeding-rate-calculator" />

<script lang="ts">
  import { onMount, onDestroy, afterUpdate } from "svelte";
  import { DOMAIN, API_PATH } from "./constants";
  import {
    storedForages,
    regionSelection,
    storedZones,
    selectedZone,
  } from "./store";
  import {
    calculateSeedingRate,
    CalculationParameters,
    OverallParameters,
  } from "./seeding-rate-calulator.js";
  import { partMap } from "./helpers.js";

  // import "../../u-pick-components/out-tsc/src/components/titled-box/index.js";
  // import "../../u-pick-components/out-tsc/src/components/up-button/index.js";

  const DROPDOWN_ICON = "/assets/img/icon/icon-arrow-down-select.png";
  // const DROPDOWN_ICON = "/assets/images/icon-arrow-down.png";

  let calculatorForages = [];
  let instandValues = [];
  let seedingRateForages = [];
  let irrigated = false;
  let reclamation = false;
  let reclamationPercentage = 50;
  let overallSeedingRate = 0;
  let seedingUnits = "lbs";
  let totalPrice = 0;
  let totalSeedingRate = 0;
  let totalInStand = 0;
  let currentZone = undefined;

  let overallParameters: OverallParameters = {};

  const unitsSingular = (units: string) => {
    return units.charAt(units.length - 1) === 's' ? units.substring(0, units.length - 1) : units
  }

  onMount(() => {
    const devLocation = window.location.hostname.match(/\.gitpod\./);
    // console.log("seeding rate mounted" , $selectedZone);
    // console.log("onmount selected zone", )
    const zi = $storedZones.findIndex((value) => value.id === $selectedZone.id);
    currentZone = $storedZones[zi];
    switchZone();
  });
  afterUpdate(() => {
    if (seedingRateForages.length === 0) {
      addForage();
    }
  });

  const addForage = () => {
    seedingRateForages = [...seedingRateForages, {}];
    instandValues = [...instandValues, 1];
    // console.log(seedingRateForages);
  };

  const removeForage = () => {
    const forages = seedingRateForages;
    const inStands = instandValues;
    forages.pop();
    inStands.pop();
    console.log("removed forage", forages);
    seedingRateForages = [...forages];
    instandValues = [...inStands];
  };
  const runCalculation = (forage) => {
    const forageParams = setForageParams(forage);
    console.log("runCalculation:forageParams: ", forageParams);
    const result = calculateSeedingRate(forageParams, overallParameters);

    return result;
  };

  const setForageParams = (forage): CalculationParameters => {
    let currentPlsSqft;
    if (currentZone) {
      currentPlsSqft = currentZone.pls_sqft ? currentZone.pls_sqft : undefined;
    }
    const result = {
      insStand: forage.percentInStand / 100,
      seedsLb: forage.seeds_lb,
      seedsKg: forage.seeds_kg,
      pls: forage.pls_sqft ? forage.pls_sqft : currentPlsSqft,
      plsSeedlot: forage.plsSeedlot / 100,
      plsCalc:
        forage.usePls === false
          ? {
              purity: forage.plsPurityPercentage / 100,
              germination: forage.plsGerminationPercentage / 100,
            }
          : undefined,
      coated: forage.isBare ? undefined : forage.coatedPercentage,
    };
    return result;
  };

  const setTotals = () => {
    totalSeedingRate = 0;
    totalPrice = 0.0;
    totalInStand = 0;
    seedingRateForages.map((forage) => {
      const fCalc = forageCalc(forage);
      totalSeedingRate = fCalc ? totalSeedingRate + fCalc : totalSeedingRate;
      totalSeedingRate = parseFloat(totalSeedingRate.toFixed(2));
      const cCost = fCalc
        ? calcCost(forage.cost, forageCalc(forage))
        : undefined;
      totalPrice = cCost ? totalPrice + cCost : totalPrice;
      totalInStand = forage.percentInStand
        ? totalInStand + forage.percentInStand * 100
        : totalInStand;
    });
  };

  const switchZone = () => {
    const typeSort = (a, b) => {
      const aNum = a.forageType[0] || 99999;
      const bNum = b.forageType[0] || 99999;
      return aNum - bNum;
    };
    let results = [];
    const hasId = currentZone && currentZone.id;
    results = hasId
      ? $storedForages.filter((forage) => forage.zones.includes(currentZone.id))
      : [...$storedForages];
    results = results.sort((a, b) => a.slug.localeCompare(b.slug));
    let tst = results.sort(typeSort);
    // console.log("switch zone", results);
    // console.log("switch zone tst", tst);

    calculatorForages = [...results];
  };

  // const selectForage = (id: number, idx: number) => {
  //   seedingRateForages[idx] = $storedForages.filter(f => f.id == id)[0];
  // }
  $: forageCalc = (forage) => {
    // [...seedingRateForages].map(forage => {
    forage.calculation = runCalculation(forage);
    return forage.calculation;
    //   console.log("reactive calculation: ", forage.calculation)
    // })
  };

  $: calcCost = (cost: number, pls: number): number => {
    return Number.parseFloat((pls * cost).toFixed(2));
  };

  // $: console.log("Change calculatorForages: ", [...calculatorForages])
  $: if ([...seedingRateForages]) {
    // console.log("Change seedingRateForages: ", [...seedingRateForages]);
    seedingRateForages.forEach((forage, idx) => {
      if (forage.percentInStand !== undefined) {
        forage.percentInStand = instandValues[idx];
      }
      return forage;
    });
    // console.log("tst", tst);
    runProcess();
  }
  $: if (irrigated) {
    // console.log("Change irrigated: ", irrigated)
    runProcess();
  }
  $: if (reclamation) {
    // console.log("Change reclamation: ", reclamation)
    runProcess();
  }
  $: if (seedingUnits) {
    // console.log("Change seeding units: ", seedingUnits);
    overallParameters.units = seedingUnits;
    runProcess();
  }
  $: if (reclamationPercentage) {
    // console.log("Change reclamation percentage: ", reclamationPercentage);
    if (reclamation === true) {
      runProcess();
    }
  }
  $: if (overallSeedingRate) {
    // console.log("Change overall seeding rate: ", overallSeedingRate)
    runProcess();
  }
  $: if (currentZone) {
    // console.log("Change currentZone: ", currentZone)
    switchZone();
    runProcess();
  }

  $: runProcess = () => {
    overallParameters.irrigated = irrigated;
    overallParameters.rateIncrease = overallSeedingRate / 100;
    overallParameters.reclamation = reclamation
      ? reclamationPercentage / 100
      : undefined;
    setTotals();
    seedingRateForages = seedingRateForages;
  };
</script>

<main style="--select-dropdown: url({DROPDOWN_ICON})">
  <!-- <h1>Seeding Rate/Cost Calculator</h1>
  <div class="description">
    <p>
      The Seeding Rate Calculator requires you to consider the soil/eco zone,
      and characteristics of the site that affect seeding rate before selecting
      individual forages to include in the mixture. Enter or modify your
      information in the Grey Boxes to automatically generate seeding rate and
      cost calculations. Wherever you see a (?) click it for more details.
    </p>
  </div> -->
  <div class="form">
    <form>
      <titled-box>
        <h2 slot="heading" style="display: flex">
          Overall Mixture
          <up-help-modal>
            <div>
              
              <h2 style="margin:0 0 35px 0;font-family:museo;font-weight:normal;font-size:43px;line-height:43px;color:#a8bb18;">Overall Mixture</h2>
              <ol>
                <li style="font-family:open-sans;font-size:19px;line-height:32px;color:rgba(0,0,0,0.7);">
                  Select your soil or regional zone from the "Your Zone" box.
                </li>
                <li style="font-family:open-sans;font-size:19px;line-height:32px;color:rgba(0,0,0,0.7);">
                  Answer the next two questions (irrigation, reclamation) that
                  will adjust the seeding rate appropriately.
                </li>
                <li style="font-family:open-sans;font-size:19px;line-height:32px;color:rgba(0,0,0,0.7);">
                  Forage U-Pick has also provided guidance for scenarios when an
                  increased seeding rate will help set the stage for a
                  successful forage stand. If required, please select a seeding
                  percentage increase that you feel matches your situation most
                  accurately.
                </li>
              </ol>
            </div>
          </up-help-modal>
        </h2>
        <div class="wrapper" slot="content">
          <p style="font-weight: bold">Your Zone</p>
          <select bind:value={currentZone} name="" class="soilZone">
            <option value={{}}>Please Select</option>
            {#each $storedZones as zone}
              {#if zone.calculator == true }
              <option value={zone} selected={zone === currentZone}>{zone.title}</option>
              {/if}
            {/each}
          </select>
          <up-soil-zone-modal>
            &nbsp;&nbsp;<a href="#viewzones" class="seedingRateCalculator-viewZones-link"
              >View zones</a
            >
          </up-soil-zone-modal>
          <hr />
          <p style="font-weight: bold">Is this an irrigated field?</p>
          <label for="irrigated-yes" class="radio-control">
            <input
              checked={irrigated === false}
              on:change={() => {
                irrigated = false;
              }}
              type="radio"
              name="irrigated"
              id="irrigated-no"
              value="0"
            />
            <label for="irrigated-no"> <span>No</span></label>
            <input
              checked={irrigated === true}
              on:change={() => (irrigated = true)}
              type="radio"
              name="irrigated"
              id="irrigated-yes"
              value="1"
            />
            <span>Yes</span></label
          >
          <hr />
          <p style="font-weight: bold">
            Are you seeding for reclamation purposes?
          </p>
          <label for="reclamation-no" class="radio-control">
            <input
              checked={reclamation === false}
              on:change={() => (reclamation = false)}
              type="radio"
              name="reclamation"
              id="reclamation-no"
              value="0"
            />
            <span>No</span></label
          >
          <label class="reclamation-yes-label" for="reclamation-yes">
          <input
            checked={reclamation === true}
            on:change={() => (reclamation = true)}
            type="radio"
            name="reclamation"
            id="reclamation-yes"
            value="1"
          />
            <span>Yes <em>(Increase by Percent)</em></span>
          </label>
            <input
              type="number"
              dir="rtl"
              value={reclamationPercentage}
              class="percentage"
              on:change={(e) =>
                (reclamationPercentage = parseInt(e.currentTarget.value))}
            /><span>%</span>
        </div>
        <div class="wrapper" slot="content">
          <p class="description-bolder">
            There are a number of situations where an increased seeding rate can
            improve a stand's establishment. Consider increasing your seeding
            rates in these situations:
          </p>
          <ul>
            <li>If fall dormant seeding - increase seeding rate by 20%</li>
            <li>
              If it is a challenging site - increase seeding rate by 10-20%
            </li>
            <li>If broadcast seeding - increase seeding rate by 20%</li>
            <li>
              For very fine stemmed alfalfa hay fields - increase seeding rate
              by 30-50%
            </li>
          </ul>
        </div>
        <div class="wrapper" slot="content">
          <p style="font-weight: bold">Increase Overall Seeding Rate</p>
          <input
            type="number"
            dir="rtl"
            value={overallSeedingRate}
            on:change={(e) =>
              (overallSeedingRate = parseInt(e.currentTarget.value))}
            class="percentage"
          />%
          <div class="seedingRateCalculator-box-callout units-callout clearfix">
            <p class="units">Units</p>
              <label for="units-lbs" class="radio-control">
                <input
                  checked={seedingUnits === "lbs"}
                  on:change={() => (seedingUnits = "lbs")}
                  type="radio"
                  name="units"
                  id="units-lbs"
                  value="lb"
                />
                <span>lbs</span></label
              >
              <label for="units-kg" class="radio-control">
                <input
                  checked={seedingUnits === "kgs"}
                  on:change={() => (seedingUnits = "kgs")}
                  type="radio"
                  name="units"
                  id="units-kg"
                  value="kg"
                />
                <span>kgs</span></label
              >
          </div>
        </div>
      </titled-box>
      {#each seedingRateForages as forage, idx}
        <titled-box>
          <h2 slot="heading" style="display: flex">
            Forage #{idx + 1}
            <!-- <a href="#" class="seedingRateCalculator-help-link-forage" ><img src= alt="Help" title="Help" /></a> -->
            <up-help-modal>
              <div class="seedingRateCalculator-help-modal">
                <h2 style="margin:0 0 35px 0;font-family:museo;font-weight:normal;font-size:43px;line-height:43px;color:#a8bb18;">Forage</h2>
                <ol>
                  <li style="font-family:open-sans;font-size:19px;line-height:32px;color:rgba(0,0,0,0.7);">
                    Select a forage species from the drop-down menu. The average
                    seeds/pound for the species is used by the calculator.
                  </li>
                  <li style="font-family:open-sans;font-size:19px;line-height:32px;color:rgba(0,0,0,0.7);">
                    Select whether the seed is bare or coated. Coated seed will
                    weigh an average of 20% more, resulting in fewer seeds per
                    pound. Adjust percentage according to your retailer's
                    coating information. Inoculation should not be considered as
                    'coated' unless it is applied with a hard, thick coating .
                  </li>
                  <li style="font-family:open-sans;font-size:19px;line-height:32px;color:rgba(0,0,0,0.7);">
                    Select the desired percentage of this forage species in the
                    stand.
                  </li>
                  <li style="font-family:open-sans;font-size:19px;line-height:32px;color:rgba(0,0,0,0.7);">
                    Select the desired percentage of this forage species in the
                    Adjust the Pure Live Seed (PLS) or the purity and
                    germination based on your seed lot information. The current
                    settings are default minimums. Higher PLS in a seed lot
                    results in fewer pounds per acre that need to be seeded.
                  </li>
                  <li style="font-family:open-sans;font-size:19px;line-height:32px;color:rgba(0,0,0,0.7);">
                    If desired, add the cost/lb of the forage seed.</li>
                  <li style="font-family:open-sans;font-size:19px;line-height:32px;color:rgba(0,0,0,0.7);">
                    The seeding rate and cost (if entered) will be displayed in
                    the box on the right-hand side. The summary of the mixture
                    will be displayed at the bottom of the page.
                  </li>
                  <li style="font-family:open-sans;font-size:19px;line-height:32px;color:rgba(0,0,0,0.7);">
                    Add additional forage species to your mixture by selecting
                    the "add forage tab" and repeating the above steps.
                  </li>
                </ol>
              </div>
            </up-help-modal>
          </h2>
          <div slot="content" class="wrapper">
            <p style="font-weight: bold">Select a Forage to Seed</p>
            <select
              bind:value={seedingRateForages[idx]}
              name=""
              class="soilZone"
            >
              <option value="">- Please Select -</option>
              {#each calculatorForages as forage, idx}
                <option value={forage}>{forage.title}</option>
              {/each}
            </select>
            <hr />
            <p style="font-weight:bold">Bare or Coated?</p>
            <label for="grassLegumeSeed-bare{idx}" class="radio-control">
              <input
                checked={forage.isBare === true}
                on:change={() => (forage.isBare = true)}
                type="radio"
                id="grassLegumeSeed-bare{idx}"
                value="bare"
              />
              <span>Bare Seed <em>(No Increase)</em></span></label
            >
            <label for="grassLegumeSeed-coated{idx}" class="radio-control">
              <input
                checked={forage.isBare === false}
                on:change={() => (forage.isBare = false)}
                type="radio"
                id="grassLegumeSeed-coated1"
                value="coated"
              />
              <span>Coated Seed <em>(Increase by Percent)</em></span></label
            ><input
              type="number"
              class="percentage"
              value={forage.coatedPercentage
                ? forage.coatedPercentage * 100
                : 20}
              on:change={(e) =>
                (forage.coatedPercentage =
                  parseInt(e.currentTarget.value) / 100)}
            />%
            <hr />
            <div
              class="forage-instand {partMap({
                'instand-overage': totalInStand > 100,
              })}"
            >
              <p style="font-weight: bold">
                What % of this forage species do you want in the forage stand?
              </p>
              <input
                type="number"
                name="percentInStand"
                class="percentage"
                value={instandValues[idx] * 100}
                on:change={(e) => {
                  forage.percentInStand = parseInt(e.currentTarget.value) / 100;
                  instandValues[idx] = parseInt(e.currentTarget.value) / 100;
                }}
              />%
              <br />
              <div style="font-style: italic;">
                <span class="total" />{totalInStand || 0}% of the mix has been
                used.
              </div>
              {#if totalInStand > 100}
                <div class="invalid-text">
                  The total "% of stand" is greater than 100%. <br />
                  Please adjust to total 100%.
                </div>
              {/if}
            </div>
          </div>
          <div slot="content" class="wrapper">
            <p style="font-weight: bold">Pure Live Seeds (PLS)</p>
            <label for="plsValueOrCalculation-value1" class="radio-control">
              <input
                type="radio"
                checked={forage.usePls === true}
                on:change={(e) => (forage.usePls = true)}
                id="plsValueOrCalculation-value1"
              />
              <span>Use PLS Value</span></label
            >
            <div style="margin-left: 15px;">
              <input
                type="number"
                class="percentage"
                value={forage.plsSeedlot}
                on:change={(e) => (forage.plsSeedlot = e.currentTarget.value)}
              />%
            </div>
            <br />
            <label for="plsValueOrCalculation-calculation-1">
              <input
                class="pls-calculation-input"
                type="radio"
                checked={forage.usePls === false}
                on:change={() => (forage.usePls = false)}
                id="plsValueOrCalculation-calculation-1"
              />
              <span>Use PLS Calculation<br />(Purity * Germination)</span
              ></label
            >
            <div style="margin-left: 15px;">
              <input
                type="number"
                class="percentage purity-input"
                value={forage.plsPurityPercentage}
                on:change={(e) =>
                  (forage.plsPurityPercentage = e.currentTarget.value)}
              />% Purity <br />
              <input
                type="number"
                class="percentage"
                value={forage.plsGerminationPercentage}
                on:change={(e) =>
                  (forage.plsGerminationPercentage = e.currentTarget.value)}
              />% Germination
            </div>
            <hr />
            <p style="font-weight: bold">Cost <em>(optional)</em></p>
            $<input
              type="number"
              step="0.01"
              name="cost"
              value={forage.cost}
              on:change={(e) => (forage.cost = e.currentTarget.value)}
            /> {unitsSingular(seedingUnits)}
          </div>
          <div slot="content" class="wrapper">
            <div
              class="seedingRateCalculator-box-callout clearfix"
              style="font-weight: bold;"
            >
              <p>PLS (Pure Live Seeds) = Purity * Germination</p>
              <p>
                A default minimum is provided based on the minimum values of
                Certified or Common #1 seed under the Canada Seeds Act. <br />
                Please adjust values to match your seed's specific PLS, purity or
                germination.
              </p>
            </div>
            <div class="seedingRateCalculator-box-totalSeedingRate">
              <p>{forage.title ? forage.title + " " : ""}Adjusted Seeding Rate</p>
              <h2>{forageCalc(forage) || "-"} {seedingUnits} / acre</h2>
              <br />
              <h2>
                ${calcCost(forage.cost, forageCalc(forage)) || "-"}  / acre
              </h2>
            </div>
          </div>
        </titled-box>
      {/each}
      <up-button class="add-button" title="Add a Forage" primary on:click={addForage} />
      <up-button
        class="remove-button"
        on:click={removeForage}
        title="Remove Last Forage"
        style="--up-button-background-primary: rgb(247, 25, 16);"
        primary
      >
        Remove Last Forage
      </up-button>
    </form>
    <div class="seedingRateCalculator-results">
      <div id="mix" class="seedingRateCalculator-results-rate">
        <div class="seedingRateCalculator-results-rate-inner">
          <p>Total Seeding Rate (of the mix):</p>
          <h2>{totalSeedingRate || 0} {seedingUnits} / acre</h2>
        </div>
      </div>
      <div class="seedingRateCalculator-results-cost">
        <div class="seedingRateCalculator-results-cost-inner">
          <p>Total Cost (of the mix):</p>
          <h2>${totalPrice || 0} / acre</h2>
        </div>
      </div>
    </div>
    <div>
      <h2>Summary</h2>
      <div class="data-table-container">
        <table class="seedingRateCalculator-summary">
          <thead>
            <tr>
              <td>Forage</td>
              <td>% in Stand</td>
              <td>Recommended Adjusted Seeding Rate</td>
              <td>Cost / {seedingUnits}</td>
              <td>Cost Total</td>
            </tr>
          </thead>
          <tbody>
            {#each seedingRateForages as forage, idx}
              <tr>
                <td>#{idx + 1}: {forage.title || ""}</td>
                <td>{forage.percentInStand * 100 || 0}%</td>
                <td>{forageCalc(forage) || "-"} {seedingUnits} / acre</td>
                <td>${forage.cost || 0}</td>
                <td>${calcCost(forage.cost, forageCalc(forage)) || 0}</td>
              </tr>
            {/each}
            <tr>
              <td style="text-align: right;"><strong>Total</strong></td>
              <td
                class="total-instand {partMap({
                  'instand-overage': totalInStand > 100,
                })}"><strong>{totalInStand}%</strong></td
              >
              <td
                ><strong>{totalSeedingRate || 0} {seedingUnits} / acre</strong
                ></td
              >
              <td />
              <td><strong>${totalPrice || 0} per acre</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <div style="margin-top: 4em;">
      <h2>Seeding Rate Tables Spreadsheet</h2>
      View the Seeding Rate Tables used by this calculator.<br />
      The file is a Microsoft Excel Spreadsheet (<em>.xlsx file extension</em>).
      <br /><br /><up-button title="Download Seeding Rate Tables" primary />
    </div> -->
  </div>
</main>

<style>
  ::-moz-selection {
    color: white;
    background-color: #a8bb18;
  }
  ::selection {
    color: white;
    background-color: #a8bb18;
  }
  :host {
    --u-pick-calculator-background-color: white;
    /* min-height: 100vh; */
    /* display: flex;
    flex-direction: column; */
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: #1a2b42;
    max-width: 1920px;
    margin: 0 auto;
    text-align: left;
    background-color: var(--u-pick-calculator-background-color);
    font-family: "open-sans", arial, sans-serif;
    font-size: 19px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.7);
  }
  :host * {
    box-sizing: border-box;
  }
  h1 {
    margin: 0 0 55px 0;
    padding: 0;
    font-family: "Museo-Light", arial, sans-serif;
    font-weight: 500 !important;
    font-size: 60px;
    line-height: 62px;
    color: #566909;
  }
  h2:not(titled-box h2) {
    margin: 0;
    font-family: "museo", arial, sans-serif;
    font-weight: 500 !important;
    font-size: 43px;
    line-height: 43px;
    color: #a8bb18;
  }
  p {
    margin-bottom: 15px;
    font-family: 'OpenSans-Bold', arial, sans-serif;
    font-size: 20px;
    line-height: 25px;
}

ul {
  padding: 0;
}

  ul li {
    margin: 0;
    padding: 10px 0 10px 30px;
    list-style: none;
    background: url("/assets/img/icon/icon-arrow.png") no-repeat 0 18px;
    border-bottom: 1px solid #e6e7e7;
    background-size: 10px auto;
    overflow: hidden;
  }

  main {
    margin: 0;
    padding: 0;
    font-family: "open-sans", arial, sans-serif;
    font-size: 19px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.7);
    flex-grow: 1;
  }
  table {
    position: relative;
    width: 100%;
    margin: 0 0 40px 0;
    background: #fff;
    border-spacing: 0;
    border: none;
  }
  table.seedingRateCalculator-summary thead tr td:first-child {
    padding-left: 12px;
  }
  table thead tr td:first-child {
    padding-left: 0;
    -webkit-text-size-adjust: 100%;
  }
  table.seedingRateCalculator-summary thead tr td {
    color: #fff;
    background: #566909;
  }
  table thead tr td {
    -webkit-text-size-adjust: 100%;
    padding: 12px;
    font-family: "OpenSans-Bold", arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #566909;
  }
  .body table tbody tr td:first-child {
    padding-left: 0;
  }
  .body table tbody tr td {
    -webkit-text-size-adjust: 100%;
    padding: 12px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #776a62;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    vertical-align: top;
  }
  table tbody tr:last-child td {
    border-top: 2px solid rgba(0, 0, 0, 0.5);
  }
  table tbody tr:nth-child(even) td {
    background: rgba(0, 0, 0, 0.05);
  }

  .reclamation-yes-label {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
  }
  .percentage {
    width: 70px;
    margin-right: 10px;
}
.purity-input {
  margin-bottom: 0px !important;
}
  .seedingRateCalculator-results-cost {
    padding-left: 25px;
  }

  .seedingRateCalculator-results-rate,
  .seedingRateCalculator-results-cost {
    float: left;
    width: 50%;
    margin-bottom: 30px;
  }
  .seedingRateCalculator-results-rate-inner {
    color: rgba(134, 92, 69, 1);
    border: 2px solid rgba(134, 92, 69, 1);
  }
  .seedingRateCalculator-results-rate-inner,
  .seedingRateCalculator-results-cost-inner {
    padding: 30px;
    margin-bottom: 20px;
  }
  .seedingRateCalculator-results-rate,
  .seedingRateCalculator-results-cost {
    float: left;
    width: 50%;
    margin-bottom: 30px;
  }
  .seedingRateCalculator-results-cost-inner {
    color: rgba(168, 187, 24, 1);
    border: 2px solid rgba(168, 187, 24, 1);
  }
  .seedingRateCalculator-results-rate-inner,
  .seedingRateCalculator-results-cost-inner {
    padding: 30px;
    margin-bottom: 20px;
  }

  .seedingRateCalculator-box-callout {
    padding: 30px;
    margin-bottom: 20px;
    background: rgba(248, 241, 219, 1);
  }
  .seedingRateCalculator-box-totalSeedingRate {
    padding: 30px;
    margin-bottom: 20px;
    color: rgba(134, 92, 69, 1);
    border: 2px solid rgba(134, 92, 69, 1);
  }
  .seedingRateCalculator-box-totalSeedingRate h2 {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 0;
    color: rgba(134, 92, 69, 1);
  }
  .seedingRateCalculator-results-rate-inner {
    color: rgba(134, 92, 69, 1);
    border: 2px solid rgba(134, 92, 69, 1);
  }
  .seedingRateCalculator-results-rate-inner p,
  .seedingRateCalculator-results-cost-inner p {
    margin-bottom: 15px;
  }
  .seedingRateCalculator-results-rate-inner h2,
  .seedingRateCalculator-results-cost-inner h2 {
    font-size: 35px;
    line-height: 35px;
    margin-bottom: 0;
  }
  .seedingRateCalculator-results-rate-inner h2 {
    color: rgba(134, 92, 69, 1);
  }
  .seedingRateCalculator-summary tbody tr:nth-child(even) td {
    background: rgba(0, 0, 0, 0.05);
  }
  .seedingRateCalculator-summary tbody tr td {
    border-bottom: 1px solid #e6e7e7;
    border-right: 1px solid #e6e7e7;
  }
.seedingRateCalculator-box-totalSeedingRate *, .seedingRateCalculator-box-callout * {
  margin: 0;
}
.seedingRateCalculator-box-totalSeedingRate p, .seedingRateCalculator-box-callout p {
  margin-bottom: 15px;
}
.pls-calculation-input {
  display: inline-block !important;
}
  table tbody tr td {
    -webkit-text-size-adjust: 100%;
    padding: 12px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #776a62;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    vertical-align: top;
  }
  
  .units-callout {
    display: flex;
    align-items: center;
  }
  .units-callout p, .units-callout label {
    width: 100px;
  }
  .units-callout p {
    margin: 0;
  }
  .instand-overage {
    border: 1px solid red;
    background-color: #f06560;
    color: #fff;
  }

  select {
    padding: 10px 60px 10px 15px;
    margin-bottom: 20px;
    font-family: "open-sans", arial, sans-serif;
    font-size: 16px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 0;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: rgba(0, 0, 0, 0.05) var(--select-dropdown) no-repeat center
      right;
    background-size: auto 9px;
    outline: none;
  }
  input[type="number"] {
    padding: 10px;
    margin-bottom: 20px;
    font-family: "open-sans", arial, sans-serif;
    font-size: 16px;
    line-height: 25px;
    text-align: right;
    color: rgba(0, 0, 0, 0.7);
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    appearance: none;
    background: rgba(0, 0, 0, 0.05);
    outline: none;
  }

  .radio-control {
    font-family: system-ui, sans-serif;
    font-size: 15px;
    line-height: 23px;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
  }
  input[type="radio"] {
    /* ...existing styles */
    width: 18px;
    height: 18px;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    border: 0.15em solid lightgrey;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    align-self: center;
  }

  input[type="radio"]:checked {
    color: lightgrey;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #a8bb18;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }
  .seedingRateCalculator-viewZones-link {
    color: #a8bb18;
  }
  up-button {
    margin-bottom: 10px;
    margin-right: 10px;
    --up-host-display: inline-block;
  }
  .seedingRateCalculator-results {
    margin-top: 50px;
  }
  .data-table-container {
    margin-top: 20px;
  }
  up-soil-zone-modal {
    display: inline-block;
  }
  .description-bolder {
    font-weight: bolder;
  }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 40px;
      line-height: 45px;
      margin-bottom: 30px;
    }
    .seedingRateCalculator-box p {
      font-size: 17px;
      line-height: 22px;
    }
    .data-table-container {
      overflow: scroll !important;
    }
    .data-table-container table {
      width: 200%;
    }
    up-button {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
      --up-host-display: block;
      /* --up-button-width: 100%; */
    }
  }

  @media screen and (max-width: 1023px) {
    .seedingRateCalculator-results-cost {
      padding-left: 0;
      margin-bottom: 20px;
    }
    .seedingRateCalculator-results-rate,
    .seedingRateCalculator-results-cost {
      float: none;
      width: 100%;
      margin-bottom: 0;
    }
  }
</style>
