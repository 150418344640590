<svelte:options tag="up-forage-to-field" />

<script lang="ts">
  import { onMount, onDestroy, afterUpdate } from "svelte";
  import { DOMAIN, API_PATH } from "./constants";
  import { storedCharacteristics, formFields, selectedZone, storedForages } from "./store";
  import { t, locale, locales } from "./i18n";

  // import "../../u-pick-components/out-tsc/src/calculators/forage-to-field/index.js";
  // import "../../u-pick-components/out-tsc/src/components/grid-layout/index.js";
  // import "../../u-pick-components/out-tsc/src/components/selection-field/index.js";
  // import "../../u-pick-components/out-tsc/src/components/up-button/index.js";

  // console.log('nfjonny-DOMAIN,API_PATH')
  // console.log( DOMAIN );
  // console.log( API_PATH );

  let formItems = [];
  $: { 
    console.log("Stored Characteristics changed",  $storedCharacteristics)
  }

  const imgHelp = '<img src="/assets/img/icon/icon-help-green.png" alt="Help" title="Help" style="width: 35px; height: 35px; transform: translateY(10px);">';

  const fetchData = async (id: number) => {
    const result = await fetch(DOMAIN + API_PATH + `characteristic/${id}`);
    const data = await result.json();
    // console.log('nfjonny-fetchData: ' + DOMAIN + API_PATH + `characteristic/${id}`);
    // console.log(data);
    return data;
  };
  const fetchChildren = async (id: number) => {
    const result = await fetch(
      DOMAIN + API_PATH + `characteristic?parent=${id}`
    );
    const data = await result.json();
    return data;
  };
  const buildCharacteristics = async () => {
    const titleRegex = /\{(.*?)\}/;
    const trimTitle = (title) => {
      let trimspot = title.match(titleRegex);
      // console.log("trimming title: ", trimspot);
      const result = trimspot ? title.slice(0, trimspot.index).trim() : title;
      return result;
    }
    const extractDescription = (description: string): {order: number, desc: string, prompt: string | undefined} => {
      const orderString = description.slice(0, 2);
      const desc = description.slice(2);
      const hasPrompt = desc.match(/###.+/);
      const prompt = hasPrompt !== null ? hasPrompt[0].replace(/###/g, "") : undefined;
      const order = orderString.match("~") ? parseInt(orderString.slice(0, 1)) : parseInt(orderString);
      return {order: order, desc: desc, prompt: prompt}
    }
    if ($formFields.length > 0) return;
    let chars = $storedCharacteristics;
    // console.log("Build characteristics storedCharacteristics", chars);
    await chars.forEach(async (c) => {
      let data = await fetchData(c);
      const parsedDesc = extractDescription(data.description);
      let field = {
        title: trimTitle(data.name),
        name: data.slug,
        postId: data.id,
        checkedId: "",
        order: parsedDesc.order,
        description: parsedDesc.desc,
        items: [],
      };
      let children = await fetchChildren(field.postId);
      children.map((child) =>{
        const childDescription = extractDescription(child.description);
        field.items.push({
          id: `${field.name}-${child.slug}`,
          postId: child.id,
          value: child.slug,
          order: childDescription.order,
          content: trimTitle(child.name).replace('&gt;', '>'),
          prompt: childDescription.prompt
        })
      }
      );
      // items = [...items, field];
      // fields.push(field);
        field.items.sort((a, b) => a.order - b.order);
        const newFields = [...$formFields, field];
        newFields.sort((a, b) => a.order - b.order);
        $formFields = [...newFields]; 
    });

    // console.log("Build Characteristics fields", fields);
    // fields.map(field => formFields.update((value) => value = [...value, field]));
    // $formFields = $formFields;
    // await fields.forEach(async field =>{
    //   let data = await fetchChilden(field.postId);
    //   let item = {
    //   }
    //   field.items.push({...item});
    // })
  };
  // afterUpdate(() => {
  //   console.log("afterUpdate fired", characteristics);
  //   data = [...characteristics]
  // })
  const invalidate = () => {
    formFields.update( fields => {
      const result = fields.map(field => {
        field.checkedId = ""
        return field
      })
      return result
    })
  }
  const handleRadio = (fieldIdx, itemIdx) => {
    if (itemIdx !== null) {
      formFields.update( fields => {
        const result = fields;
        // console.log("Handle radio changed", result[fieldIdx]);
        result[fieldIdx].checkedId = result[fieldIdx].items[itemIdx].id;
        // console.log("Handle radio changed", result[fieldIdx]);
        return result;
      })
    } else {
      formFields.update( fields => {
        const result = fields;
        result[fieldIdx].checkedId = "";
        // console.log("Handle radio changed", result[fieldIdx]);
        return result;
      })
    }
  };
  onMount(async () => {
    console.log("F2F mounted form items", formItems);
    formFields.subscribe( value => {
      // console.log("formFields subscription update: ", value);
      formItems = [...value];
      // formItems = {...$formFields};
    });
    storedCharacteristics.subscribe(async () => {
      formItems = [];
      await buildCharacteristics();
      formItems = [...formItems, ...$formFields];
    })
    // await buildCharacteristics();
  });
  const sortFormItems = () => {

  }

  const submitForm = () => {
    let slug = "/forage?param=" 
    const redirectUrl = `${slug}${$selectedZone.slug ? $selectedZone.slug : ''}`;
    window.location.href = redirectUrl;
  };
</script>

  <main>
  <h1 class="f2f-title">
    {$t("my_field_characteristics")} <a
      class="selectionField-fieldCharacteristics"
      href="#info"
      style="margin-left:-20px;"
      >
      <up-help-modal>
        <div class="seedingRateCalculator-help-modal">
          <h2>{$t("my_field_characteristics")}</h2>
          <p style="font-family:open-sans;font-size:19px;line-height:32px;margin:0 0 35px 0;">{$t("consider_selecting_forages")}</p>
          <p style="font-family:open-sans;font-size:19px;line-height:32px;margin:0 0 35px 0;">{$t("to_see_all_forages")}</p>
          <p style="font-family:open-sans;font-size:19px;line-height:32px;margin:0 0 35px 0;"><strong>{$t("tip")}:</strong> {$t("single_fields_often_have_combinations")}</p>
        </div>
      </up-help-modal>
  </h1>
  <p class="directions-one">{$t("please_select_modify_any_one_or_more")}<br />{$t("then_hit_view_results")}</p>
  <p class="directions-two">{$t("tip_for_best_results_choose_1_3")}</p>
  <p class="directions-two">{@html $t("wherever_you_see_a_click_it_for_more_details").replace('__help__', imgHelp)}</p>
  <div class="form">
    <form
      method="POST"
      action="/submit"
      id="field-form"
    >
      <div class="selectionFieldCharacteristics-grid">
        {#each formItems as item, itemIdx}
          <div class="selectionFieldCharacteristics-grid-box">
            <div style="width:100%" >
              <h2><span>{item.title}</span>
                {#if item.description.length > 0}
                <span style="position:absolute;">
                  <up-tooltip-modal description="{item.description}" title="{item.title}">
                    <img src="/assets/img/icon/icon-help-green.png" alt="Help" title="Help" style="width:25px;height:25px;transform:translate(4px,1px);cursor:pointer;">
                  </up-tooltip-modal>
                </span>
                {/if}
              </h2>
            </div>
            <input
              type="radio"
              name={item.name}
              id="{item.name}-No preference"
              value="No preference"
              checked={item.checkedId === ""}
              on:click={() => handleRadio(itemIdx, null)}
            />
            <label for="{item.name}-No preference">
              <span>{$t("no_preference")}</span>
            </label>
            {#each item.items as sel, selIdx}
              <input
                type="radio"
                name={item.name}
                id={`${sel.id}`}
                value={sel.value}
                data-postId={sel.postId}
                checked={item.checkedId === sel.id}
                on:click={() => {
                  console.log(sel)
                  if (sel.prompt && sel.prompt.length > 0) {
                    if (window.confirm(sel.prompt)) {
                      handleRadio(itemIdx, selIdx);
                    } 
                  } else {
                    handleRadio(itemIdx, selIdx)
                  }
                }
                }
              /><label for={sel.id}><span>{@html sel.content.replace(/\(/g, "<em>(").replace(/\)/g, ")</em>")}</span></label>
            {/each}
          </div>
        {/each}
        {#if formItems.length % 2 === 0 }
          <div class="selectionFieldCharacteristics-grid-box inactive-box" style="border:none;"></div>
          <div class="selectionFieldCharacteristics-grid-box inactive-box" style="border:none;"></div>
        {:else }
          <div class="selectionFieldCharacteristics-grid-box inactive-box" style="border:none;"></div>
        {/if}
      </div>

      <up-button title="{$t("clear_selections")}" on:click={invalidate} />
      <up-button class="loading" title="..." data-count="{$storedForages.length}" primary style="pointer-events:none;" ></up-button>
      <up-button class="view-results" title="{$t("view_results")}" data-count="{$storedForages.length}" primary on:click={submitForm} style="--up-button-color-hover:white" />

    </form>
  </div>
</main>

<style>
  * {
    box-sizing: border-box;
  }
  ::-moz-selection {
  color: white;
  background-color: #a8bb18;
}
::selection {
  color: white;
  background-color: #a8bb18;
}
  main {
    --u-pick-calculator-background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: #1a2b42;
    max-width: 1920px;
    margin: 0 auto;
    text-align: center;
    background-color: var(--u-pick-calculator-background-color);
  }
  h1 {
    padding: 15px;
    margin: 0 0 60px 0;
    font-family: "museo", arial, sans-serif;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    color: #a8bb18;
    background: #faf5e6;
  }
  h2 {
    margin: 0 0 35px 0;
    font-family: "museo", arial, sans-serif;
    font-weight: normal !important;
    font-size: 43px;
    line-height: 43px;
    color: #a8bb18;
  }
  p strong {
    font-weight: bold;
    text-decoration: underline;
  }

  main {
    margin: 0;
    padding: 0;
    font-family: "open-sans", arial, sans-serif;
    font-size: 19px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.7);
    flex-grow: 1;
  }

  form,
  input,
  textarea {
    margin: 0;
    padding: 0;
    outline: none;
    border-radius: 0;
  }
  .logo {
    margin-top: 36px;
    animation: app-logo-spin infinite 20s linear;
  }

  .app-footer {
    font-size: calc(12px + 0.5vmin);
    align-items: center;
  }

  .app-footer a {
    margin-left: 5px;
  }
  .selectionFieldCharacteristics-grid {
    /* min-height: 100vh; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
  }
  .selectionFieldCharacteristics-grid-box {
    box-sizing: border-box;
    flex-basis: 23.5%;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 0 40px 0;
    padding: 40px 40px 30px 40px;
    text-align: center;
    border: 2px solid #e6e7e7;
    background: #fff;
  }
  .selectionFieldCharacteristics-grid-box h2 {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 26px;
    text-align: left;
    color: #566909;
  }
  .selectionFieldCharacteristics-grid-box input[type="radio"] {
    display: none;
  }
  .selectionFieldCharacteristics-grid-box input[type="radio"] + label span {
    display: block;
    position: relative;
    padding: 0 0 10px 35px;
    text-align: left;
    line-height: 22px;
    cursor: pointer;
  }
  .selectionFieldCharacteristics-grid-box
    input[type="radio"]:checked
    + label
    span:after {
    background-position: 0 -18px;
  }
  .selectionFieldCharacteristics-grid-box
    input[type="radio"]
    + label
    span:after {
    display: block;
    position: absolute;
    top: 2px;
    left: 0;
    width: 18px;
    height: 18px;
    content: "";
    background: url("/assets/images/icon-radio.png")
      no-repeat 0 0;
    background-size: 18px auto;
  }
  .f2f-title {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    font-weight: normal;
  }
  .directions-one {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    margin: 0px 0px 40px;
  }
  up-button {
    --up-button-width: 100%;
    margin-bottom: 15px;
  }
  up-button.view-results {
    display: block;
  }
  up-button.view-results[data-count="0"] {
    display: none;
  }
  up-button.loading {
    display: none;
  }
  up-button.loading[data-count="0"] {
    display: block;
  }
  @media screen and (max-width: 1830px) {
    .selectionFieldCharacteristics-grid-box {
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 1439px) {
    .selectionFieldCharacteristics-grid-box {
      flex-basis: 31%;
      margin-bottom: 40px;
    }
  }
  @media screen and (max-width: 1023px) {
    .selectionFieldCharacteristics-grid-box {
      flex-basis: 40%;
      margin-bottom: 30px;
    }
  }
  @media screen and (min-width: 767px) {
  up-button {
    --up-button-width: auto;
    width: fit-content;
    float: left;
    margin-left: 15px;
}
  }
  @media screen and (max-width: 767px) {
    .selectionFieldCharacteristics-grid-box {
      flex-basis: 100%;
      margin-bottom: 20px;
    }
    .inactive-box {
      padding-top: 0px;
      padding-bottom: 7px;
    }
  }
</style>
