<svelte:options tag="up-tooltip-modal" />

<script>
  let showModal = false;
  export let title = "";
  export let description = "";
</script>


<main>
  <div on:click={() => (showModal = true)}>
    <slot />
  </div>
  {#if showModal }
  <nf-modal on:close={() => (showModal = false)}>
    <div class="tooltip-modal">
      <div class="seedingRateCalculator-help-modal">
        <h2>{@html title.replace(/\(/g, "<em>(").replace(/\)/g, ")</em>")}</h2>
        <p>{@html description.replace(/\n/g, "<br />").replace(/<strong>/g, "<strong><u>").replace(/<\/strong>/g, "</u></strong>")}</p>
      </div>
    </div>
  </nf-modal>
  {/if}
</main>

<style>
  nf-modal::part(modal) {
    position: relative;
    width: calc(100vw / 1.3);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  nf-modal::part(popup-container) {
    display: grid;
    align-items: center;
  }
  nf-modal::part(close-button) {
    top: -13px;
  }
  .tooltip-modal {
    padding: 50px;
    background: #faf5e6;
    font-family: 'museo', arial, sans-serif;
    font-size: 30px;
    line-height: 33px;
    /* text-align: center; */
    text-align: left;
    color: rgba(0,0,0,0.3);
  }
  .seedingRateCalculator-help-modal h2, 
  .tooltip-modal h2 {
    margin: 0 0 35px 0;
    font-family: 'museo', arial, sans-serif;
    font-weight: normal !important;
    font-size: 43px;
    line-height: 43px;
    color: #a8bb18;
  }
  .seedingRateCalculator-help-modal p, 
  .tooltip-modal p {
    font-family: 'open-sans', arial, sans-serif;
    font-size: 19px;
    line-height: 32px;
    color: rgba(0,0,0,0.7);
  }
  .seedingRateCalculator-help-modal p strong, 
  .tooltip-modal p strong {
    font-weight: bold;
    text-decoration: underline;
  }
  @media screen and (min-width: 767px) {
    nf-modal::part(modal) {
      max-width: 475px;
      max-width: 600px;
    }
  }
  @media screen and (max-width: 767px) {
    nf-modal::part(modal) {
      width: 98vw;
      max-width: 98vw;
      left: 1vw;
      margin-left: 0;
    }
  }
</style>
