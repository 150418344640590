import { writableSession } from './store-util';
export const regionSelection = writableSession('regionSelection', { region: '', zone: '' });
export const selectedFields = writableSession('selectedFields', []);
export const storedForages = writableSession('storedForages', []);
export const storedZones = writableSession('storedZones', []);
export const storedCharacteristics = writableSession('storedCharacteristics', []);
export const selectedZone = writableSession('selectedZone', {});
export const formFields = writableSession('formFields', []);
export const installationTime = writableSession('installationTime', 0);
export const flashMessages = writableSession('flashMessages', []);
export const resetStorage = () => {
    regionSelection.set({ region: '', zone: '' });
    selectedFields.set([]);
    storedForages.set([]);
    storedZones.set([]);
    storedCharacteristics.set([]);
    selectedZone.set({});
    formFields.set([]);
    installationTime.set(Date.now());
};
export const flushFlashMessages = (args) => {
    args.forEach((filteredMessage) => flashMessages.update((messages) => [...messages.filter((m) => m.route !== filteredMessage.route)]));
};
