const calcPls = (purity, germination) => {
    const result = purity * germination;
    console.log('Calculating pls: ', result);
    return result;
};
const calcPlcAcre = (pls, inStand) => {
    const sqftacre = 43560;
    let result = pls * sqftacre * (inStand * 100);
    console.log('step one: ', result);
    return result;
};
const calcTwo = (rate, seedsLb, plsSeedlot) => {
    let result = rate / (seedsLb * plsSeedlot);
    return result;
};
export const calculateSeedingRate = (params, overall) => {
    let item = params;
    let unitVal;
    switch (overall.units) {
        case 'lbs':
            unitVal = params.seedsLb;
            break;
        case 'kgs':
            unitVal = params.seedsKg;
            break;
        default:
            unitVal = undefined;
    }
    if (item.plsCalc) {
        item.plsSeedlot = calcPls(item.plsCalc.purity, item.plsCalc.germination);
    }
    if (item.coated) {
        item.insStand = item.insStand * (1 + item.coated);
    }
    if (overall.rateIncrease) {
        item.insStand = item.insStand * (1 + overall.rateIncrease);
    }
    if (overall.irrigated === true) {
        item.pls = 40;
    }
    if (overall.reclamation) {
        item.insStand = item.insStand * (1 + overall.reclamation);
    }
    let result = calcPlcAcre(item.pls, item.insStand);
    result = calcTwo(result, unitVal, item.plsSeedlot);
    result = Number.parseFloat(result.toFixed(2));
    return result;
};
