<svelte:options tag="up-soil-zone-modal" />

<script>
  // import "../../u-pick-components/out-tsc/src/components/forage-card/index.js";
  // import "../../u-pick-components/out-tsc/src/components/top-three/index.js";

  let showModal = false;
  let tabs = [true, false, false, false, false];
  let images = [
    ["/assets/img/Soil-Zone-Map-BC.png", 'BC'],
    ["/assets/img/Soil-Zone-Map-AB.png", 'AB'],
    ["/assets/img/Soil-Zone-Map-SK.png", 'SK'],
    ["/assets/img/Soil-Zone-Map-MB.png", 'MB'],
    ["/assets/img/Soil-Zone-Map-ON.jpg", 'ON']
  ];

  const setTab = (tabIndex) => {
    const newTabs = tabs.map((tab, idx) => {
      tab = idx == tabIndex ? true : false;
      return tab;
    });
    tabs = [...newTabs];
  };
</script>

<main>
  <div on:click={() => (showModal = true)}>
    <slot />
  </div>
  {#if showModal}
    <nf-modal on:close={() => (showModal = false)}>
      <div
        class="seedingRateCalculator-viewZones-modal modal"
        style="text-align: center;"
      >
        <br><h2>Soil Zones</h2>
        <div id="tabs" class="r-tabs">
          <ul class="r-tabs-nav">
            {#each tabs as tab, idx}
              <li
                class="r-tabs-tab {tab
                  ? 'r-tabs-state-active'
                  : 'r-tabs-state-default'}"
              >
                <a href="#tab-{images[idx][1]}" on:click={() => setTab(idx)} class="r-tabs-anchor"
                  >{images[idx][1]}</a
                >
              </li>
            {/each}
            <!-- <li
              class="r-tabs-tab {tabs[0]
                ? 'r-tabs-state-active'
                : 'r-tabs-state-default'}"
            >
              <a href="#tab-bc" on:click={() => setTab(0)} class="r-tabs-anchor"
                >BC</a
              >
            </li>
            <li
              class="r-tabs-tab {tabs[1]
                ? 'r-tabs-state-active'
                : 'r-tabs-state-default'}"
            >
              <a href="#tab-ab" on:click={() => setTab(1)} class="r-tabs-anchor"
                >AB</a
              >
            </li>
            <li
              class="r-tabs-tab {tabs[2]
                ? 'r-tabs-state-active'
                : 'r-tabs-state-default'}"
            >
              <a href="#tab-sk" on:click={() => setTab(2)} class="r-tabs-anchor"
                >SK</a
              >
            </li>
            <li
              class="r-tabs-tab {tabs[3]
                ? 'r-tabs-state-active'
                : 'r-tabs-state-default'}"
            >
              <a href="#tab-mb" on:click={() => setTab(3)} class="r-tabs-anchor"
                >MB</a
              >
            </li> -->
          </ul>
          {#each tabs as tab, idx}
            {#if tab}
            <div
              class="r-tabs-accordion-title {tab ? 'r-tabs-state-active' :'r-tabs-state-default'}"
              style=""
            >
              <a href="#tab-{images[idx][1]}" class="r-tabs-anchor"
                >{images[idx][1]}</a
              >
            </div>
            <div
              id="tab-{images[idx][1]}"
              class="r-tabs-panel {tab ? 'r-tabs-state-active' :'r-tabs-state-default'}"
              style="display: block;"
            >
              <img
                class="map-{images[idx][1]}"
                src="{images[idx][0]}"
                alt="{images[idx][1]}"
                style="max-height: 70vh;width: auto;max-width: 100%"
              />
            </div>
            {/if}
          {/each}
          <!-- {#if tabs[0]}
            <div
              class="r-tabs-accordion-title {tabs[0]
                ? 'r-tabs-state-active'
                : 'r-tabs-state-default'}"
              style=""
            >
              <a href="#tab-bc" class="r-tabs-anchor">BC</a>
            </div>
            <div
              id="tab-bc"
              class="r-tabs-panel {tabs[0] ? 'r-tabs-state-active' :'r-tabs-state-default'}"
              style="display: block;"
            >
              <img
                class="map-bc"
                src="{images[0]}"
                alt="British Columbia"
                style="max-height: 70vh;width: auto;max-width: 100%"
              />
            </div>
          {/if}
          {#if tabs[1]}
            <div
              class="r-tabs-accordion-title {tabs[1]
                ? 'r-tabs-state-active'
                : 'r-tabs-state-default'}"
              style=""
            >
              <a href="#tab-ab" class="r-tabs-anchor">AB</a>
            </div>
            <div
              id="tab-ab"
              class="r-tabs-panel {tabs[1] ? 'r-tabs-state-active' :'r-tabs-state-default'}"
              style="display: block;"
            >
              <img
                class="map-ab"
                src="{images[1]}"
                alt="Alberta"
                style="max-height: 70vh;width: auto;max-width: 100%"
              />
            </div>
          {/if}
          {#if tabs[2]}
            <div class="r-tabs-accordion-title {tabs[2] ? 'r-tabs-state-active' :'r-tabs-state-default'}" style="">
              <a href="#tab-sk" class="r-tabs-anchor">SK</a>
            </div>
            <div
              id="tab-sk"
              class="r-tabs-panel {tabs[2] ? 'r-tabs-state-active' :'r-tabs-state-default'}"
              style="display: block;"
            >
              <img
                class="map-sk"
                src="{images[2]}}"
                alt="Saskatchewan"
                style="max-height: 70vh;width: auto;max-width: 100%"
              />
            </div>
          {/if}
          {#if tabs[3]}
            <div class="r-tabs-accordion-title {tabs[3] ? 'r-tabs-state-active' :'r-tabs-state-default'}" style="">
              <a href="#tab-mb" class="r-tabs-anchor">MB</a>
            </div>
            <div
              id="tab-mb"
              class="r-tabs-panel {tabs[3] ? 'r-tabs-state-active' :'r-tabs-state-default'}"
              style="display: block;"
            >
              <img
                class="map-mb"
                src="{images[3]}"
                alt="Manitoba"
                style="max-height: 70vh;width: auto;max-width: 100%"
              />
            </div>
          {/if} -->
        </div>
      </div>
    </nf-modal>
  {/if}
</main>

<style>
  ::slotted(*) {
    font-family: "museo", arial, sans-serif;
  }
  nf-modal::part(modal) {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 50px;
  }
  nf-modal::part(popup-container) {
    display: flex;
    justify-content: center;
  }
  nf-modal::part(close-button) {
    top: -10px;
  }
  ::slotted(div[slot="content"]) {
    position: relative;
    top: 30px;
    padding: 35px;
    background: #a8bb18;
    width: 98vw;
    margin-left: auto;
    margin-right: auto;
    max-height: 100vh;
    overflow: scroll;
  }
  ::slotted(h2) {
    margin: 0 0 10px 0;
    font-family: "museo", arial, sans-serif;
    font-size: 38px;
    line-height: 42px;
    color: #fff;
  }
  ::slotted(h3) {
    padding: 0;
    margin: 0 0 25px 0;
    font-family: "museo", arial, sans-serif;
    font-style: italic;
    font-size: 24px;
    line-height: 28px;
    color: #566909;
    background: none;
  }
  ::slotted(li) {
    position: relative;
    margin: 0;
    padding: 15px 0 15px 50px;
    overflow: hidden;
  }
  img {
    width: 100%;
  }
  h2 {
    margin: 0 0 35px 0;
    font-family: "museo", arial, sans-serif;
    font-weight: normal !important;
    font-size: 43px;
    line-height: 43px;
    color: #a8bb18;
  }
  .r-tabs .r-tabs-nav {
    background: none;
  }
  .r-tabs .r-tabs-nav {
    margin: 0;
    padding: 0;
  }
  .seedingRateCalculator-viewZones-modal {
    /* width: 800px; */
    padding-top: 50px;
  }
  .r-tabs .r-tabs-accordion-title {
    display: none;
  }
  .r-tabs .r-tabs-nav .r-tabs-tab {
    background: none;
    position: relative;
  }
  .r-tabs .r-tabs-tab {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 0;
    overflow: visible;
  }
  .modal {
    max-width: 1000px;
    box-sizing: border-box;
    background: #fff;
    padding: 0;
    text-align: left;
  }
  @media screen and (min-width: 800px) {
    .modal { width: 800px; }
  }
  .r-tabs .r-tabs-nav .r-tabs-anchor {
    display: inline-block;
    padding: 15px 22px;
    font-family: "museo", sans-serif;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
    border-bottom: 4px solid #d9d9d9;
  }
  .r-tabs .r-tabs-panel.r-tabs-state-active {
    display: block;
  }
  .r-tabs .r-tabs-panel {
    margin-top: -4px;
    padding: 50px 50px 15px 50px;
    background: rgba(0, 0, 0, 0.05);
    border-top: 4px solid #d9d9d9;
  }
  .r-tabs .r-tabs-panel {
    padding: 15px;
    display: none;
  }
  .r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
    color: #566909;
    text-shadow: none;
    border-bottom: 4px solid #566909;
    text-decoration: none;
  }
  @media screen and (max-width: 1830px) {
    .r-tabs .r-tabs-nav .r-tabs-anchor {
      padding: 13px 16px;
    }
  }
  @media screen and (max-width: 1439px) {
    .r-tabs .r-tabs-nav .r-tabs-anchor {
      padding: 13px 14px;
    }
  }
  @media screen and (max-width: 1300px) {
    .r-tabs .r-tabs-nav .r-tabs-anchor {
      padding: 13px 10px;
      font-size: 18px;
      line-height: 18px;
    }
  }
  @media screen and (max-width: 1023px) {
    .r-tabs .r-tabs-nav .r-tabs-anchor {
      padding: 13px 6px;
      font-size: 14px;
      line-height: 14px;
    }
  }
  @media screen and (min-width: 767px) {
    nf-modal::part(modal) {
      max-width: 1475px;
    }
  }
  @media screen and (max-width: 767px) {
    nf-modal::part(modal) {
      width: 98vw;
      max-width: 98vw;
      left: 1vw;
      margin-left: 0;
    }
  }
</style>
