<svelte:options tag="up-path-navigator" />
<script lang="ts">
    export let path = "#";

    const handleClick = (p: string) => {
        const path = 
        // console.log("Navigating to " + path);
        window.location.href = p;
    }
</script>
<main on:click={() => handleClick(path)}>
  <slot></slot>
</main>