<svelte:options tag="up-home-template" />
<script>
  // Add any necessary JavaScript or TypeScript code here
  import { t } from './i18n.js';
  const template = $t('hometemplate');
</script>

<style>
  /* Add your CSS styles here */
  .homeContent {
    padding: 0 110px 340px 110px;
    margin: 0 auto;
    background: url('/assets/img/layout/bg-home.jpg') no-repeat top center;
}
h1 {
    margin: 0 0 55px 0;
    padding: 0;
    font-family: 'Museo', arial, sans-serif;
    font-weight: 100 !important;
    font-size: 60px;
    line-height: 62px;
    color: #566909;
}
.homeContent h1 {
    padding-top: 0;
    padding-bottom: 50px;
    font-size: 53px;
    line-height: 57px;
        padding: 25px 0 70px 0;
    margin-bottom: 0;
    text-align: center;
}
  .homeContent-features {
    text-align: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: -22px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.homeContent-features-item {
    display: inline-block;
    padding: 60px 30px 0 30px;
    margin: 0 60px 0 0;
    width: 275px;
    height: 275px;
    font-family: 'Museo', arial, sans-serif;
    font-size: 31px;
    line-height: 36px;
    color: #566909;
    border-radius: 50%;
    background: #fff;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
}
.homeContent-features-item:hover {
  color: #a8bb18;
  transform: scale(1.1);
  box-shadow: 0 0 30px rgba(0,0,0,0.1);
}
.homeContent-features-item img {
    display: block;
    margin: 0 auto 20px auto;
    width: 90px;
    height: auto;
}

@media screen and (max-width: 1439px) {
  .homeContent {
    background-size: 1500px auto;
    padding-bottom: 230px;
  }

  .homeContent-features-item {
    padding: 40px 35px 0 35px;
    margin-right: 20px;
    width: 180px;
    height: 220px;
    font-size: 23px;
    line-height: 28px;
    text-decoration: none;
  }
}

@media screen and (max-width: 1023px) {
  .homeContent {
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 170px;
    background-size: 1200px auto;
  }

  .homeContent h1 {
    font-size: 40px;
    line-height: 45px;
  }

  .homeContent-features-item {
    padding: 40px 35px 0 35px;
    width: 130px;
    height: 160px;
    font-size: 18px;
    line-height: 24px;
  }

  .homeContent-features-item img {
    width: 60px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 888px) {
  .homeContent {
    padding-bottom: 142px;
  }
}
@media screen and (max-width: 767px) {
  .homeContent {
    padding-bottom: 50px;
    background: #ffffff;
    background: -moz-linear-gradient(top, #ffffff 0%, #faf5e6 50%);
    background: -webkit-linear-gradient(top, #ffffff 0%, #faf5e6 50%);
    background: linear-gradient(to bottom, #ffffff 0%, #faf5e6 50%);
  }

  .homeContent h1 {
    padding-left: 0;
    padding-right: 0;
    font-size: 30px;
    line-height: 35px;
  }

  .homeContent-features-item {
    margin: 0 0 25px 0;
  }
}

</style>

<main>
  <div class="homeContent">
    <h1>{template.title}<br />{template.title2}</h1>
    <div class="homeContent-features">
      <a href="/field" class="homeContent-features-item">
        <img src="/assets/img/icon/icon-home-feature-field.png" alt={template.features.suitedToMyField}>
        {template.features.suitedToMyField}
      </a>
      <a href="/seeding-rate-calculator" class="homeContent-features-item">
        <img src="/assets/img/icon/icon-home-feature-calculator.png" alt={template.features.seedingRateCalculator}>
        {template.features.seedingRateCalculator}
      </a>
      <a href="/weed" class="homeContent-features-item">
        <img src="/assets/img/icon/icon-home-feature-weed.png" alt={template.features.forageWeedManagement}>
        {template.features.forageWeedManagement}
      </a>
    </div>
  </div>
</main>
