<svelte:options tag="up-forage-modal" />

<script lang="ts">
  import { t, locale, locales } from "./i18n";
  //   import "../../u-pick-components/out-tsc/src/components/forage-card/index.js";
  //   import "../../u-pick-components/out-tsc/src/components/top-three/index.js";

  let showModal = false;
  export let detailsurl = "";
  export let heading = "";
  export let subheading = "";
  export let pointone = "";
  export let pointtwo = "";
  export let pointthree = "";
  export let headingimage = "";
  export let active = "true";
</script>


<main>
  <div on:click={() => (showModal = true)}>
    <slot />
  </div>
  {#if showModal && active === "true"}
    <nf-modal on:close={() => (showModal = false)}>
      <forage-card
        {detailsurl}
        {heading}
        {subheading}
        {pointone}
        {pointtwo}
        {pointthree}
        {headingimage}
      />
    </nf-modal>
  {/if}
  {#if showModal && active === "false"}
    <nf-modal on:close={() => (showModal = false)}>
      <div class="forageModal-inactive">
        <p>
          {$t("This forage is not suited for your currently selected field characteristics")}
        </p>
        <a href="/field" class="change-characteristics">
          {$t("Change my field characteristics")}
        </a>
        <br /><br />
        <a href="#close" on:click={() => (showModal = false)} rel="modal:close" style="padding-right:0;">
          {$t("Close")}
        </a>
      </div>
    </nf-modal>
  {/if}
</main>

<style>
  nf-modal::part(modal) {
    position: relative;
    /* top: 10%;
    left: 50%; */
    width: calc(100vw / 1.3);
    /* max-width: 475px; */
    /* margin-left: -20%; */
    margin-left: auto;
    margin-right: auto;
  }
  nf-modal::part(close-button) {
    top: -13px;
  }
  nf-modal::part(popup-container) {
    display: grid;
    align-items: center;
  }
  .forageModal-inactive {
    padding: 50px;
    background: #faf5e6;
    font-family: 'museo', arial, sans-serif;
    font-size: 30px;
    line-height: 33px;
    text-align: center;
    color: rgba(0,0,0,0.3);
}
.forageModal-inactive p {
    margin-bottom: 20px;
}
a {
  text-decoration: none;
}
.forageModal-inactive a {
    font-size: 18px;
    line-height: 22px;
    padding-right: 35px;
    position: relative;
    color: #a8bb18;
    display: inline-block;
}
.forageModal-inactive a.change-characteristics:after {
    content: "";
    position: absolute;
    top: -3px;
    right: 0;
    width: 24px;
    height: 30px;
    background: url("/assets/img/icon/icon-switch.png") no-repeat top left;
    background-size: 24px auto;
    transition: all .25s ease-in-out;
}
.forageModal-inactive a:hover:after {
    right: -7px;
}
  @media screen and (min-width: 767px) {
    nf-modal::part(modal) {
      max-width: 475px;
    }
  }
  @media screen and (max-width: 767px) {
    nf-modal::part(modal) {
      width: 98vw;
      max-width: 98vw;
      left: 1vw;
      margin-left: 0;
    }
  }
</style>
