<svelte:options tag="up-scroller" />
<script lang="ts">
    export let location= "#";

    const handleClick = (p: string) => {
        const elm = document.getElementById(p);
        elm && elm.scrollIntoView();
    }
</script>
<main on:click={() => handleClick(location)}>
  <slot></slot>
</main>