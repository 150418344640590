export default {
  en: {

    // title
    "my_field_characteristics": "My Field Characteristics",

    // overlay window
    "consider_selecting_forages": "Consider selecting one set of forages for a specific area of a field, and then selecting more generally for other areas of a field.",
    "to_see_all_forages": "To see all the forages suited to your zone leave all the field characteristics as “no preference”.",
    "tip": "TIP",
    "single_fields_often_have_combinations": "Single fields often have combinations of many different characteristics (such as saline and non-saline, or different flooding times). For areas of variable topography or field characteristics, consider running different scenarios for different areas of your field.",

    // main page
    "please_select_modify_any_one_or_more": "Please select/modify any one or more of the following 10 selection criteria based on your field and your needs.",
    "then_hit_view_results": "Then hit “View Results” to view forages that are suitable to your field.",
    "tip_for_best_results_choose_1_3": "TIP: For best results choose 1-3 selection criteria that are the highest priorities for your field.",
    "wherever_you_see_a_click_it_for_more_details": "Wherever you see a __help__ click it for more details.",

    "no_preference": "No preference",
    "clear_selections": "Clear Selections",
    "view_results": "View Results",
    "This forage is not suited for your currently selected field characteristics": "This forage is not suited for your currently selected field characteristics",
    "Change my field characteristics": "Change my field characteristics",
    "Close": "Close",
    "Forage_U_Pick": "Forage U-Pick",
    "Return_to_Home": "Return to Home",
    "Forages_Suited_to_My_Field": "Forages Suited to My Field",
    "Seeding_Rate_Calculator": "Seeding Rate Calculator",
    "Forage_Weed_Management": "Forage Weed Management",
    "Glossary": "Glossary",
    "Information": "Information",
    "Acknowledgements": "Acknowledgements",
    "Help": "Help",
    "Main_Menu": "Main Menu",
    "My_Soil_Zone": "My Soil Zone",
    "My_Zone": "My Zone",
    "Select_Zone": "Select Zone",
    "mobile_language_text": "Français",
    "mobile_language_link": "https://upick.fr.beefresearch.ca/",
    "hometemplate": {
      "title": 'An interactive forage species ',
      "title2": 'selection tool for Canada',
      "features": {
        "suitedToMyField": 'Forages Suited to My Field',
        "seedingRateCalculator": 'Seeding Rate Calculator',
        "forageWeedManagement": 'Forage Weed Management',
      },
    },
  },

  fr: {

    // title
    "my_field_characteristics": "Boussole Fourragère: mes caractéristiques des champs",

    // overlay window
    "consider_selecting_forages": "Envisagez de sélectionner les fourrages pour vos champs en fonction de leurs caractéristiques spécifiques.",
    "to_see_all_forages": "Pour voir tous les fourrages, cliquez sur le bouton «Voir tous les fourrages» ci-dessous.",
    "tip": "Astuce",
    "single_fields_often_have_combinations": "Les champs uniques ont souvent des combinaisons de fourrages qui fonctionnent bien ensemble.",

    // main page
    "please_select_modify_any_one_or_more": "Les producteurs peuvent se servir des caractéristiques suivantes pour faire face aux limitations",
    "then_hit_view_results": "de production ou aux préférences de gestion dans leur champ.",
    "tip_for_best_results_choose_1_3": "Il est suggéré aux producteurs de sélectionner jusqu'à trois caractéristiques pour aider à affiner leurs options, sans être trop contraignantes.",
    "wherever_you_see_a_click_it_for_more_details": "Lorsque vous voyez un __help__ cliquez dessus pour obtenir plus de détails.",

    "no_preference": "Aucune préférence",
    "clear_selections": "Effacer les Sélections",
    "view_results": "Voir les résultats",
    "This forage is not suited for your currently selected field characteristics": "Ce fourrage ne convient pas aux caractéristiques de terrain que vous avez sélectionnées.",
    "Change my field characteristics": "Changer mes caractéristiques de terrain",
    "Close": "Fermer",
    "Forage_U_Pick": "Boussole fourragère",
    "Return_to_Home": "Retour à la page d'accueil",
    "Forages_Suited_to_My_Field": "Fourrages adaptés à mon champ",
    "Seeding_Rate_Calculator": "Calculette de taux de semis",
    "Forage_Weed_Management": "Gestion des mauvaises herbes",
    "Glossary": "Glossaire",
    "Information": "L'information",
    "Acknowledgements": "Remerciements",
    "Help": "Aider",
    "Main_Menu": "Menu principal",
    "My_Soil_Zone": "Ma zone de sol",
    "My_Zone": "Ma région",
    "Select_Zone": "Sélectionner une région",
    "mobile_language_text": "English",
    "mobile_language_link": "https://upick.beefresearch.ca/", 
    "hometemplate": {
      "title": "Un outil interactif pour la sélection ",
      "title2": 'des espèces fourragères au Canada',
      "features": {
        "suitedToMyField": 'Fourrages adaptés à mon champ',
        "seedingRateCalculator": 'Calculateur de taux de semis',
        "forageWeedManagement": 'Gestion des mauvaises herbes',
      },
    },
  }
};
