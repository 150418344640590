<svelte:options tag="up-zone-specific" />

<script lang="ts">
  import { regionSelection } from "./store";
  export let location = "";

  const locationCheck = (locString: string) => {
    let shown = false;
    const loc = locString.split(",");
    loc.forEach((l) => {
      if (l === $regionSelection.zone) {
        shown = true;
      }
    });
    return shown;
  };
  $: visible = locationCheck(location);
</script>

<main>
  {#if visible}
    <slot />
  {/if}
</main>
