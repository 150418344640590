<svelte:options tag="up-forage-navigator" />
<script lang="ts">
    import { regionSelection } from "./store";

    const handleClick = () => {
        const path = `/forage/?param=${$regionSelection.zone}`
        // console.log("Navigating to " + path);
        window.location.href = path;
    }
</script>
<main on:click={() => handleClick()}>
  <slot></slot>
</main>