<svelte:options tag="up-weed-modal" />
<script>
  // import "../../u-pick-components/out-tsc/src/components/forage-card/index.js";
  // import "../../u-pick-components/out-tsc/src/components/top-three/index.js";

  let showModal = false;

</script>

<main>
    <div on:click={() => showModal = true}>
        <slot name="anchor"></slot>
    </div>
    {#if showModal}
    <nf-modal on:close={() => showModal = false}>
      <slot name="content"></slot>
    </nf-modal>
    {/if}
</main>
<style>
  ::slotted(*) {
    /* font-family: 'museo', arial, sans-serif; */
  }
  nf-modal::slotted(a[slot="anchor"]) {
    font-family: 'open-sans' sans-serif;
  }
    nf-modal::part(modal){
        position: relative;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        max-width: 1000px;
    }
    ::slotted(div[slot="content"]) { 
      position: relative;
      top: 30px;
      padding: 35px;
      background: #a8bb18;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      height: fit-content;
    }
    ::slotted(h2) {
      margin: 0 0 10px 0;
    font-family: 'museo', arial, sans-serif;
    font-size: 38px;
    line-height: 42px;
    color: #fff;
    }
    ::slotted(h3) {
      padding: 0;
    margin: 0 0 25px 0;
    font-family: 'museo', arial, sans-serif;
    font-style: italic;
    font-size: 24px;
    line-height: 28px;
    color: #566909;
    background: none;
    }
    ::slotted(li) {
      position: relative;
    margin: 0;
    padding: 15px 0 15px 50px;
    overflow: hidden;
    }
    @media screen and (min-width: 767px) {
      nf-modal::part(modal) {
        max-width: 1000px;
      }
    }
    @media screen and (max-width: 767px) {
      nf-modal::part(modal) {
        width: 98vw;
        max-width: 100%;
        margin-left: 0;
      }
    }
</style>
