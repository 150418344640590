<svelte:options tag="up-flash-message" />
<script lang="ts">
  import { onMount } from 'svelte';
    // import { IFlashMessage } from './global.js';
    import { flashMessages } from "./store";
    let showMessage = false;
    export let route = "";

    const hasMessage = () => {
        const filtered = $flashMessages.map((message) => message.route === route);
        // console.log("Filtered flash messages: ",  filtered);
        if (filtered[0]) {
            showMessage = true;
        } else {
            showMessage = false;
        }
    }
    $: {
        // console.log("Flash messages changed" , [...$flashMessages]);
        // hasMessage();
    }

    onMount(() => {
        // $flashMessages.push({ route: 'forage'});
        flashMessages.subscribe(() => {
            hasMessage();
        })
    })


</script>
<main>
  {#if showMessage }
    <div part="message">
        <slot></slot>
    </div>
  {/if }
</main>