<svelte:options tag="up-navigator" />
<script lang="ts">
    import { regionSelection } from "./store";

    export let zone = null
    export let region = null

    const handleClick = () => {
      if (region === null) {
        regionSelection.set({  ...$regionSelection, zone: zone  });
        window.location.href = "field";
      } else {
        regionSelection.set({  zone, region  });
        window.location.href = "field";
      }
    }
    $: console.log("Changed region", $regionSelection)
</script>
<main on:click={() => handleClick()}>
  <slot></slot>
</main>