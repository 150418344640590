export var ApiMethodEnum;
(function (ApiMethodEnum) {
    ApiMethodEnum["GET"] = "GET";
})(ApiMethodEnum || (ApiMethodEnum = {}));
export var ApiMethodResource;
(function (ApiMethodResource) {
    ApiMethodResource["FORAGES"] = "forages";
})(ApiMethodResource || (ApiMethodResource = {}));
export var ApiPageEnum;
(function (ApiPageEnum) {
    ApiPageEnum[ApiPageEnum["ALL"] = 0] = "ALL";
})(ApiPageEnum || (ApiPageEnum = {}));
import { DOMAIN, API_PATH } from "./constants";
const base = DOMAIN + API_PATH;
let fetchedData = [];
export async function api(method, resource) {
    const req = async (page = null) => await fetch(`${base}${resource}${page ? `?page=${page}` : ''}`, {
        method,
        headers: {
            'content-type': 'application/json'
        },
    });
    const res = await req();
    const countHeader = parseInt(res.headers.get('x-wp-total'));
    const pagesHeader = parseInt(res.headers.get('x-wp-totalpages'));
    // console.log(countHeader, pagesHeader)
    // console.log("API RESPONESE HEADERS", res)
    if (res.ok && pagesHeader > 0) {
        const requestData = await res.json();
        let allData = [...requestData];
        for (let i = 2; i <= pagesHeader; i++) {
            const pageRequest = await req(i);
            if (pageRequest.ok) {
                let reqData = await pageRequest.json();
                allData = allData.concat(reqData);
            }
        }
        fetchedData = [...allData];
    }
    else {
        fetchedData = await res.json();
    }
    if (res.ok && method !== 'GET') {
        return {
            status: 303,
            headers: {
                location: '/'
            }
        };
    }
    return {
        status: res.status,
        body: fetchedData
    };
}
