<svelte:options tag="up-help-modal" />

<script>
  //   import "../../u-pick-components/out-tsc/src/components/forage-card/index.js";
  //   import "../../u-pick-components/out-tsc/src/components/top-three/index.js";

  let showModal = false;
</script>


<main>
  <div on:click={() => (showModal = true)}>
    <img src="/assets/img/icon/icon-help-green.png" alt="Help" title="Help">
  </div>
  {#if showModal }
    <nf-modal on:close={() => (showModal = false)}>
        <div class="help-modal">
            <slot></slot>
        </div>
    </nf-modal>
  {/if}
</main>

<style>
  nf-modal::part(modal) {
    position: relative;
    /* top: 10%;
    left: 50%; */
    width: calc(100vw / 1.3);
    /* max-width: 475px; */
    /* max-width: 800px; */
    /* margin-left: -20%; */
    margin-left: auto;
    margin-right: auto;
  }
  nf-modal::part(popup-container) {
    display: grid;
    align-items: center;
  }
  nf-modal::part(close-button) {
    top: -13px;
  }
  .help-modal {
    padding: 50px;
    background: white;
    font-family: 'museo', arial, sans-serif;
    padding: 50px;
    font-size: 19px;
    line-height: 32px;
    color: rgba(0,0,0,0.7);
  }
  .help-modal ::slotted(h2){
    margin: 0 0 35px 0;
    font-family: 'museo', arial, sans-serif;
    font-weight: normal !important;
    font-size: 43px;
    line-height: 43px;
    color: #a8bb18;
  }
  .help-modal ::slotted(p){
    font-family: 'open-sans', arial, sans-serif;
    font-size: 19px;
    line-height: 32px;
    color: rgba(0,0,0,0.7);
  }
  .help-modal ::slotted(strong){ font-weight: bold; }
  img {
    width: 35px;
    height: 35px;
    margin-bottom: -7px;
    margin-left: 10px;
    cursor: pointer;
  }
  @media screen and (min-width: 767px) {
    nf-modal::part(modal) {
      /* max-width: 475px; */
      max-width: 800px;
    }
  }
  @media screen and (max-width: 767px) {
    nf-modal::part(modal) {
      width: 98vw;
      max-width: 98vw;
      left: 1vw;
      margin-left: 0;
    }
  }
</style>
