<svelte:options tag="nf-modal" />

<script>
  import { createEventDispatcher, onDestroy } from "svelte";
  import { get_current_component, stop_propagation } from "svelte/internal";

  // import "../../u-pick-components/out-tsc/src/components/forage-card/index.js";
  export let ref = null;

  const component = get_current_component();
  const svelteDispatcher = createEventDispatcher();

  const dispatch = (name, detail) => {
    svelteDispatcher(name, detail);
    component.dispatchEvent &&
      component.dispatchEvent(new CustomEvent(name, { detail }));
  };
  const modalClickDispatcher = (node) => {
    if (node) {
      dispatch("clicked_modal");
    }
  }
  const clickOutside = (node) => {
    const handleClick = event => {
    // console.log("modal node", node)
    // console.log("clicked node", event.target)
    if (node) {
      dispatch("click_outside")
    }
  }

	document.addEventListener('click', handleClick, true);
  
  return {
    destroy() {
      document.removeEventListener('click', handleClick, true);
    }
	}
  }
  const close = () => dispatch("close");

  function onClickOutside(event) {
    close();
  }

  let modal;

  const handle_keydown = (e) => {
    if (e.key === "Escape") {
      close();
      return;
    }

    if (e.key === "Tab") {
      // trap focus
      const nodes = modal.querySelectorAll("*");
      const tabbable = Array.from(nodes).filter((n) => n.tabIndex >= 0);

      let index = tabbable.indexOf(document.activeElement);
      if (index === -1 && e.shiftKey) index = 0;

      index += tabbable.length + (e.shiftKey ? -1 : 1);
      index %= tabbable.length;

      tabbable[index].focus();
      e.preventDefault();
    }
  };

  const previously_focused =
    typeof document !== "undefined" && document.activeElement;

  if (previously_focused) {
    onDestroy(() => {
      previously_focused.focus();
    });
  }
</script>

<svelte:window on:keydown={handle_keydown} />

<div class="popup-container" part="popup-container" on:click={() => close()} >
  <div on:click={(e) => {console.log("stopping click"); e.stopPropagation()}} class="modal" role="dialog" aria-modal="true" bind:this={modal} part="modal">
    <!-- <slot name="header"></slot> -->
    <!-- <hr> -->
    <slot />

    <!-- svelte-ignore a11y-autofocus -->
    <!-- <button autofocus on:click={close} bind:this={ref}>close modal</button> -->
    <a part="close-button" rel="modal:close" class="close-modal " on:click={close}
      >Close</a>
  </div>
</div>

<style>
  * {
    box-sizing: border-box;
  }
  .modal-background, .popup-container  {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 9999;
    /* padding: 20px; */
    padding: 0px;
    box-sizing: border-box;
    background-color: rgba(46, 48, 2, 0.9);
    text-align: center;
  }

  .modal {
    position: absolute;
    /* left: 50%;
    top: 50%; */
    /* width: calc(100vw - 4em); */
    /* max-width: 32em; */
    /* height: calc(100vh - 4em); */
    overflow: visible;
    /* transform: translate(-50%, -50%); */
    /* padding: 1em; */
    /* border-radius: 0.2em; */
    /* background: white; */
    z-index: 99999;
    vertical-align: middle;
    position: relative;
    max-width: 1000px;
    box-sizing: border-box;
    padding: 0;
    text-align: left;
    top: 30px;
  }

  button {
    display: block;
  }
  .modal a.close-modal {
    position: absolute;
    z-index: 9999999999;
    top: 20px;
    right: -12.5px;
    display: block;
    width: 30px;
    height: 30px;
    text-indent: -9999px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("/assets/img/icon/icon-close.png");
    background-size: 30px auto;
}
</style>
